.report .content {
  @apply flex flex-col;
}
.report .content h2 {
  @apply text-xl font-medium capitalize mb-2;
}
.report .content h3 {
  @apply font-semibold capitalize mb-1;
}
.report .content p {
  @apply text-base mb-5 max-w-2xl leading-loose text-red-500;
}
