.m-panel-wrap {
  @apply border-b-4 border-neutral-800;
}
.m-panel-grid {
  @apply grid grid-cols-1 lg:grid-cols-2  gap-5 md:gap-10 px-5;
}
.m-panel {
  @apply pt-5 md:pt-20 pb-5 md:pb-20;
}
.m-panel div {
}
.m-panel-wrap h1 {
  @apply text-2xl md:text-4xl font-semibold leading-none mb-2 max-w-md;
}
.m-panel-wrap h2 {
  @apply text-sm md:text-lg font-normal max-w-md mb-3 leading-tight;
}

.m-button {
  @apply bg-white border-black border-2 rounded-lg px-4 py-1.5 font-semibold text-sm;
}
.slide-in {
  animation: slideIn 0.5s forwards;
}

@keyframes slideRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes slideIn {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}

.scrolling-wrapper {
  width: 100%;
}

.scrolling-content {
  @apply flex gap-x-3;
  white-space: nowrap;

  animation: slideRight 40s linear infinite;
}

.blinking-cursor {
  @apply pl-0.5  bg-neutral-500 ml-0.5;
  animation: blink 0.5s infinite alternate;
}

@keyframes blink {
  0%,
  49% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
